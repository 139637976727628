import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({
  name,
  style,
}) => (
  <i className={`icon-${name}`} style={style} />
);

Icon.propTypes = {
  name: PropTypes.oneOf([
    'arrow-right',
    'arrow-up',
    'check',
    'calendar',
    'edit',
    'user',
    'user-complete',
    'zoom',
  ]).isRequired,
  style: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

Icon.defaultProps = {
  style: {},
};

export default Icon;
