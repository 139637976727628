// import AuthResource from './auth';
import { Resource } from '@rest-hooks/rest';
import config from '../config';

export default class HotelResource extends Resource {
  id = undefined;

  companyId = '';

  name = '';

  code = '';

  integrationKey = '';

  pAirId = '';

  pAirToken = '';

  documentThresholdAge = '';

  logoUrl = '';

  translations = [];

  fields = [];

  colors = {
    colorMain: '',
    colorSecondary: '',
    colorBackground: '',
    colorActive: '',
    colorDisabled: '',
    colorCard: '',
    colorError: '',
    colorLight: '',
    colorDark: '',
  };

  pk() {
    return this.id?.toString();
  }

  static urlRoot = `${config.backendUri}/api/v1/hotels`;
}
